import * as React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper';
import Seo from '../components/seo';
import HeroImageA from '../components/MainHero';

const Gallery = () => {

  const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, max-content));
    justify-content: space-evenly;

    column-gap: 1em;
    row-gap: 2em;
  `;
  // for staticImage start
  const width = 360;
  const height = 360;
  // for staticImage end
  return (
    <Layout>
      <Seo title='Gallery, Top1 Painting' description='Some of our work' />

        {/* Hero Image is found in /components/MainHero.js */}
        <HeroImageA />

      <SiteWidthWrapper>
        <h1>Our Gallery</h1>
        <p style={{marginBottom: `64px`}}>Below is a brief collection of our work. This gallery includes a few
          in-progress pieces, before, and afters in no particular order.</p>

        <div style={{width: `100%`, margin: `0 auto`}}>
          <GridWrapper>
            <StaticImage
                src='../images/gallery/gallery_1.jpg'
                alt='gallery image 1'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='eager'
            />
            <StaticImage
                src='../images/gallery/gallery_2.jpg'
                alt='gallery image 2'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='eager'
            />
            <StaticImage
                src='../images/gallery/gallery_3.jpg'
                alt='gallery image 4'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='eager'
            />
            <StaticImage
                src='../images/gallery/gallery_4.jpeg'
                alt='gallery image 4'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_5.jpeg'
                alt='gallery image 5'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_6.jpeg'
                alt='gallery image 6'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_7.jpg'
                alt='gallery image 7'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_8.jpg'
                alt='gallery image 8'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_9.jpg'
                alt='gallery image 9'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_10.jpg'
                alt='gallery image 10'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_11.jpg'
                alt='gallery image 11'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_12.jpg'
                alt='gallery image 12'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_13.jpg'
                alt='gallery image 13'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_14.jpg'
                alt='gallery image 14'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_15.jpg'
                alt='gallery image 15'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_16.jpg'
                alt='gallery image 16'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_17.jpg'
                alt='gallery image 17'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_18.jpg'
                alt='gallery image 18'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_19.jpg'
                alt='gallery image 19'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_20.jpg'
                alt='gallery image 20'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_21.jpg'
                alt='gallery image 21'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_22.jpg'
                alt='gallery image 22'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_23.jpg'
                alt='gallery image 23'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_24.jpg'
                alt='gallery image 24'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_25.jpg'
                alt='gallery image 25'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_26.jpg'
                alt='gallery image 26'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_27.jpg'
                alt='gallery image 27'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_28.jpg'
                alt='gallery image 28'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_29.jpg'
                alt='gallery image 29'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_30.jpg'
                alt='gallery image 30'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_31.jpg'
                alt='gallery image 31'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_32.jpg'
                alt='gallery image 32'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_33.jpg'
                alt='gallery image 33'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
            <StaticImage
                src='../images/gallery/gallery_34.jpg'
                alt='gallery image 34'
                objectFit='contain'
                width={width}
                height={height}
                layout='fixed'
                quality={95}
                placeholder='dominantColor'
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading='lazy'
            />
          </GridWrapper>
        </div>
      </SiteWidthWrapper>
    </Layout>
  );
};

export default Gallery;
